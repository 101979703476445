import './Disclaimer.css'
import Modal from '../Modal/Modal'

export default function Disclaimer ({ setShowDisclaimer }) {
  const handleConfirm = async () => {
    setShowDisclaimer(false)
  }

  const disclaimer = 'You are using the beta test version of GreenGPT, a generative AI chatbot designed to help you adopt a low-carbon lifestyle and discover cash incentives and other financial benefits of climate-friendly living. Developed by Future, GreenGPT incorporates tools from OpenAI and leverages Future\'s in-house research. \nFuture is a climate-focused financial technology company offering rewards for everyday low-carbon purchases and paying members for reduced emissions. GreenGPT may occasionally produce inaccurate information about people, places, or facts and should not be considered a source of financial, legal or medical advice. Any chat history or other interactions with this beta version of GreenGPT may be used to further enhance and refine the AI model.'

  return (
    <Modal>
      <div className="disclaimer-container">
        <p className="disclaimer">
          {disclaimer}
        </p>
        <button className="confirm" onClick={handleConfirm}>Confirm</button>
      </div>
    </Modal>
  )
}
