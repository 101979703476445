import './App.css'
import logo from './images/logo.png'
import ChatBubble from './ChatBubble/ChatBubble'
import UserInput from './UserInput/UserInput'
import { useEffect, useRef, useState } from 'react'
import uniqid from 'uniqid'
import { getResponse, saveFeedback } from './api/api'
import Disclaimer from './Disclaimer/Disclaimer'

function App () {
  const [showDisclaimer, setShowDisclaimer] = useState(true)
  const [chatId, setChatId] = useState()
  const [promptNumber, setPromptNumber] = useState(1)
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState([])
  const [loading, setLoading] = useState(true)
  const phantomRef = useRef()

  const addQuestion = async question => {
    setQuestions([...questions, question])
    setLoading(true)
    const response = await getResponse(chatId, question, questions.slice(0, questions.length), answers.map(({ answer }) => answer))
    setAnswers([...answers, response])
    setLoading(false)
  }

  const save = async (queryId, vote, feedback) => {
    await saveFeedback(chatId, queryId, vote, feedback, questions.slice(0, questions.length), answers.map(({ answer }) => answer))
  }

  useEffect(() => {
    if (!showDisclaimer) {
      setTimeout(() => {
        setLoading(false)
        if (promptNumber === 1) {
          setLoading(true)
          setPromptNumber(2)
          setTimeout(() => {setLoading(false)}, 1000)
        }
      }, 1000)
      setChatId(uniqid())
    }
  }, [showDisclaimer])

  useEffect(() => {
    phantomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [questions, answers])

  const initialPrompts = []
  for (let i = 1; i <= promptNumber; i++) {
    if (loading && i === promptNumber && questions.length === 0) {
      initialPrompts.push(<ChatBubble key={`initial-${i}`} role="bot" loading={loading}/>)
    } else if (i === 1) {
      initialPrompts.push(<ChatBubble key={`initial-${i}`} role="bot"
                                      message="Hi there! I am GreenGPT. I am here to help you adopt a low-carbon lifestyle and discover cash incentives and other financial benefits of climate-friendly living. You are among the first to try me. Please give a thumps up or down on my answers to help me get better.&#128578;"/>)
    } else if (i === 2) {
      initialPrompts.push(<ChatBubble key={`initial-${i}`} role="bot" message="Here are examples of topics you can ask me about:"
                                      selections={['Best tax credits to lower my carbon footprint', 'Average carbon footprint in the US', 'Fuel savings from electric cars', 'Top fashion brands with secondhand options', 'How to save a ton of carbon']}
                                      setQuestions={addQuestion}/>)
    }
  }

  const chat = []
  questions.forEach((question, index) => {
    chat.push(<ChatBubble key={`question-${index}`} role="user" message={question} showFeedback={true}/>)
    if (loading && index === questions.length - 1) {
      chat.push(<ChatBubble key={`answer-${index}`} role="bot" loading={loading} showFeedback={true}/>)
    }
    if (answers.length - 1 >= index) {
      chat.push(<ChatBubble key={`answer-${index}`} role="bot" message={answers[index].answer} showFeedback={true}
                            saveFeedback={(vote, feedback) => save(answers[index].queryId, vote, feedback)}/>)
    }
  })

  return (
    <div className="app">
      <div className="logo-container">
        <img className="logo" src={logo} alt="Future logo" onClick={() => window.open('https://future.green')}/>
        <p className="logo-subscript">Beta</p>
      </div>
      <div className="conversation-container">
        {showDisclaimer ? <Disclaimer setShowDisclaimer={setShowDisclaimer}/> :
          <>
            <div className="chat-container">
              {initialPrompts}
              {chat}
              <div className="phantom-div" ref={phantomRef}/>
            </div>
            {<UserInput addQuestion={addQuestion} disabled={loading}/>}
            <div className="footnote">GreenGPT may occasionally produce inaccurate information about people, places, or facts and should not
              be considered a source of financial, legal or medical advice.
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default App
