import axios from 'axios'

// const apiBaseUrl = 'http://greengpt-api-dev.eba-se2u3k86.us-east-1.elasticbeanstalk.com'
const apiBaseUrl = 'https://greengpt-api.futurestash.com'

export async function getResponse (chatId, question, questionHistory, answerHistory) {
  const axiosResponse = await axios.post(`${apiBaseUrl}/get_response`, {
    chat_id: chatId,
    query: question,
    chat_history_query: questionHistory,
    chat_history_response: answerHistory
  })
  return {
    queryId: axiosResponse.data.query_id,
    answer: axiosResponse.data.output
  }
}

export async function saveFeedback (chatId, queryId, vote, feedback, questionHistory, answerHistory) {
  const payload = {
    chat_id: chatId,
    query_id: queryId,
    vote_feedback: vote === 'good' ? 1 : -1,
    text_feedback: feedback,
    chat_history_query: questionHistory,
    chat_history_response: answerHistory
  }
  await axios.post(`${apiBaseUrl}/user_feedback`, payload)
}
